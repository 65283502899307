<template>
    <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 overflow-auto">
      <div class="w-3/12 overflow-auto border-r-2 pt-10 px-5 border-gray-400 text-black mr-12">
          <div class="tab" @click="selectTab(1)" v-bind:class="{ active: isActive && currentTab == 1 }">
            <p>lead sources settings</p>
          </div>
          <div class="tab my-5" @click="selectTab(2)" v-bind:class="{ active: isActive && currentTab == 2 }">
            <p>close reasons</p>
          </div>
          
          <div class="mt-8">
          <p class="active-text" v-if="currentTab === 1">
            <ReferralSourcesDescription ></ReferralSourcesDescription>
          </p>

          <p class="active-text" v-if="currentTab === 3">
            Select which primary amenities are in your units. Then, select the
            secondary details that apply to each primary amenity. Note that some
            primary unit amenities are selected automatically. For these, you
            are still encouraged to select the appropriate secondary amenities.
          </p>
          <p class="active-text" v-if="currentTab === 4">
            First, select which fees apply to your community. <br />
            Next, input the amount of those fees. <br />
            Your digital human will use this information to answer questions
            about community fees. <br />
            You can fully customize your digital human's response by going into
            manage the conversation, then selecting FAQs as the main topic, and
            fees as the conversation detail, and finally selecting which fee you
            would like to customize in next level detail.
          </p>
          </div>
      </div>
      <div class="flex h-full no-scroll">
        <div >
          <div class="flex flex-grow content" v-if="currentTab === 1">
            <ReferralSourcesList  @cancel="currentTab=0"></ReferralSourcesList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/ui/Loader'
import ReferralSourcesList from "@/components/guest_cards/settings/ReferralSources/ReferralSourcesList";
import ReferralSourcesDescription from "@/components/guest_cards/settings/ReferralSources/ReferralSourcesDescription";

export default {
    name:'GuestCardsSettings',
    components: {ReferralSourcesList, ReferralSourcesDescription,Loader},
    data() {
        return {
          loading: false,
          currentTab: 0,
          isActive: true,
          currentCommunity: undefined,
        }
      },
    methods: {
        selectTab(nextTab) {
          if (this.currentTab !== nextTab) {
            this.isActive = true;
            this.currentTab = nextTab;
          }
        },
    },
}
</script>
<style scoped>
 .active {
    @apply text-blue-500;
    border-left: 4px solid lightskyblue;
  }
  .active-text{
     @apply text-blue-500;
  }
  .tab {
    padding: 5px;
    cursor: pointer;
  }
  .no-scroll{
       overflow: hidden;
   }
</style>